import revive_payload_client_xjejdGD8JNsdqnZz62pYNAIkalgSsttbkKPK1YRWtrg from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OITJ_RdTtD3tiLTocEhUAUIEKasr4w8DFOLX7vBW4_Q from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Not_jY6h0zNfYBMMqBmCuG2g_BMsX_ScG2HGS9x9zQM from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8 from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_gk4QsEC0uLCwn9bKncNR3sRkmU6vVoCmHmTRHsrgSGI from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HpR0JdFPQSF8ZlznGUe0Hv6eXPpQrGyd0FmORa__rDo from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_JFvhK_tzIhjcwOxPBp5PEn3viDqN6_uVLyWHqOaVQ6U from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_immediate_client_2vCmSpGLgbG42Cfr0VblrIraITykfMNWKOQk29NyZnE from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/app/plugins/chunk-reload-immediate.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/apps/website/.nuxt/components.plugin.mjs";
import prefetch_client_WFGjB_gj7gIoOdgi16bsQcG7HojjuYnL8TGyGVLx_ec from "/app/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_@capacitor+preferences@6.0.3_@parcel+watcher@2.5.1_@types+no_zwz256ctexgo37ivzy4gy63jri/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOWn_pCo2Iq6yfNIGiSUFb9nOydnK1TWc85Aa1HQQ5M from "/app/node_modules/.pnpm/nuxt-aos@1.2.5_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_terser@5.39.0_tsx@4.19.3_yaml@2.7.0_/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import strapi_RJaPDVa2w5laXZcwjhDZkMJqTT53_5x5teYnoVauum0 from "/app/node_modules/.pnpm/@nuxtjs+strapi@2.0.0_magicast@0.3.5/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import plugin_WCczwdS1EJFMy7nfDjYsa7uJhJDPJHgLrH1KqJTLBfY from "/app/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_terser@5.39.0_tsx_ejwevtdw4k7jzf36hdzv4eqgau/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ from "/app/apps/website/.nuxt/floating-vue.mjs";
import i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws from "/app/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_KXA6sPMHjBaGJz8pzXm8V5BUnQNp_Cq1zNfsPjWVR4I from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_a03xXbvISmoFrx_6_K_xdKl5_ou2qhwEmRC8tz5oQFU from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4.36.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_eIspl7F5p_477nkhaGV2cFXD4kJ6pE_AYfUsKduSYEE from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4.36.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_i397hgmKg_bEX06H6AuJ_mjRdaiplv0mrSX3lfhRa5U from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4.36.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import motion_bSk6lAKTu9kvDyECO4p8ZAqU1ODu0FxOCtICq0P0JKA from "/app/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import components_client_IJb8e9PqXIuWRhDVlwy48WbRQ3d9E__inrzEbfRfCGw from "/app/node_modules/.pnpm/nuxt-swiper@2.0.0_magicast@0.3.5/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII from "/app/apps/website/.nuxt/unocss.mjs";
import dayjs_locale_DStV2c_b1mk6LyQyoJ_WkxUYupODKwKg7PzO88oCeIE from "/app/apps/website/app/plugins/dayjs-locale.ts";
import floating_vue_client_gES0KYpd7d3l0sNAkeQhwkzJUpL72hyyRBspVywkEuM from "/app/apps/website/app/plugins/floating-vue.client.ts";
import sonner_client_MevXIaaLDMNTg0eLD7Hn16GNByl3rJaYmWRYuIfRT4w from "/app/apps/website/app/plugins/sonner.client.ts";
import strapi_client_hSLLFMvrtWEmKqLiPYTUxmiOHpsgZ94p6BXgx_pWZX8 from "/app/apps/website/app/plugins/strapi.client.ts";
import trpc_client_ggnsO9ROketL7YfpflVrLBV_wI3jAA7bWMmR7SyvBrQ from "/app/apps/website/app/plugins/trpc-client.ts";
import unhead_fwFz_H7XEKYB4_d7THci_DjZGMRUdMdOzrqL6YQc6a8 from "/app/apps/website/app/plugins/unhead.ts";
import update_app_config_RGBSSXxKPv_ApiQPv6U9BLlTSB8IQwBQnR_Qli3wC1s from "/app/apps/website/app/plugins/update-app-config.ts";
import ssg_detect__HZEMy7eXI7kll5b6lkNItxm8_2MRFdELcpiSXNyCeY from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_rollup@4.36.0_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_xjejdGD8JNsdqnZz62pYNAIkalgSsttbkKPK1YRWtrg,
  unhead_OITJ_RdTtD3tiLTocEhUAUIEKasr4w8DFOLX7vBW4_Q,
  router_Not_jY6h0zNfYBMMqBmCuG2g_BMsX_ScG2HGS9x9zQM,
  _0_siteConfig_5_Df5MibenU9q7hOWAlwbNrMpfjqEZ03E35FowsCVs8,
  payload_client_gk4QsEC0uLCwn9bKncNR3sRkmU6vVoCmHmTRHsrgSGI,
  navigation_repaint_client_HpR0JdFPQSF8ZlznGUe0Hv6eXPpQrGyd0FmORa__rDo,
  check_outdated_build_client_JFvhK_tzIhjcwOxPBp5PEn3viDqN6_uVLyWHqOaVQ6U,
  chunk_reload_immediate_client_2vCmSpGLgbG42Cfr0VblrIraITykfMNWKOQk29NyZnE,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_WFGjB_gj7gIoOdgi16bsQcG7HojjuYnL8TGyGVLx_ec,
  plugin_AOWn_pCo2Iq6yfNIGiSUFb9nOydnK1TWc85Aa1HQQ5M,
  plugin_iWn8_GdXitKtqrehWImnVMjDFJxFmauHmo7ehWl7JRo,
  strapi_RJaPDVa2w5laXZcwjhDZkMJqTT53_5x5teYnoVauum0,
  plugin_WCczwdS1EJFMy7nfDjYsa7uJhJDPJHgLrH1KqJTLBfY,
  floating_vue_0VJpPLm37OmsRfOoVrjUztB_kqo8e0M_PqepnJl50BQ,
  i18n_vpNs8i0cr9N1X_z0gnT94vc4uwUE_tOHJq1wXR6Srws,
  plugin_KXA6sPMHjBaGJz8pzXm8V5BUnQNp_Cq1zNfsPjWVR4I,
  switch_locale_path_ssr_a03xXbvISmoFrx_6_K_xdKl5_ou2qhwEmRC8tz5oQFU,
  route_locale_detect_eIspl7F5p_477nkhaGV2cFXD4kJ6pE_AYfUsKduSYEE,
  i18n_i397hgmKg_bEX06H6AuJ_mjRdaiplv0mrSX3lfhRa5U,
  motion_bSk6lAKTu9kvDyECO4p8ZAqU1ODu0FxOCtICq0P0JKA,
  components_client_IJb8e9PqXIuWRhDVlwy48WbRQ3d9E__inrzEbfRfCGw,
  unocss_vGb1_aULWT_QMA72v4pP_J70ltB9GmEtNEUl8vHsuII,
  dayjs_locale_DStV2c_b1mk6LyQyoJ_WkxUYupODKwKg7PzO88oCeIE,
  floating_vue_client_gES0KYpd7d3l0sNAkeQhwkzJUpL72hyyRBspVywkEuM,
  sonner_client_MevXIaaLDMNTg0eLD7Hn16GNByl3rJaYmWRYuIfRT4w,
  strapi_client_hSLLFMvrtWEmKqLiPYTUxmiOHpsgZ94p6BXgx_pWZX8,
  trpc_client_ggnsO9ROketL7YfpflVrLBV_wI3jAA7bWMmR7SyvBrQ,
  unhead_fwFz_H7XEKYB4_d7THci_DjZGMRUdMdOzrqL6YQc6a8,
  update_app_config_RGBSSXxKPv_ApiQPv6U9BLlTSB8IQwBQnR_Qli3wC1s,
  ssg_detect__HZEMy7eXI7kll5b6lkNItxm8_2MRFdELcpiSXNyCeY
]